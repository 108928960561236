import { Component, OnInit, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { PrimeIcons } from 'primeng/api';
import { ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { map } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ProjectService } from '../../user/services/project.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { apiUrl } from '../../../environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PostService } from '../../user/services/post.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { SharedashboardService } from '../../user/services/sharedashboard.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-public-shareable-dashboard',
  templateUrl: './public-shareable-dashboard.component.html',
  styleUrls: ['./public-shareable-dashboard.component.scss']
})
export class PublicShareableDashboardComponent implements OnInit {
  // @ViewChild('proj') proj: MatSelect;

  // share button
  public link: string;
  lat = 22.4064172;
  long = 69.0750171;
  zoom=7;
  
  modalRef: BsModalRef;
  projects:any[] = [];
  project:[] ;
  apiLink = apiUrl;
  log_frame:any = [];
  activity_metrics:any = null;
  activity_map_data:any = null;
  project_share_data:any = null;
  
  // States
  loading = false;
  loadingProjects = true;
  filterProjs = false;

  userDetails: any;
  activeIndex: number = 0;
  dataItem: any[];
  ///timeline
  events: any[];
  ///google map
  map: google.maps.Map;

  filterForm: UntypedFormGroup = this.formBuilder.group({
    proj: ['']
  });

  getProjects:any = null;
  Projects:any = null;
  getSdgs:any = null;
  proj_id: any;
  momentView = moment;
  POSTs:any = [];
  org: string;  
  proj : any;
  video_url:any=null;
  ///map style
  styles: any[] = [
    { elementType: 'geometry', stylers: [{color: '#203e58'}] },
    { elementType: 'labels.text.stroke', stylers: [{color: '#ffffff'}] },
    { elementType: 'labels.text.fill', stylers: [{visibility: 'off'}] },
    { featureType: 'water', elementType: 'geometry', stylers: [{color: '#0e1626'}] },
    { featureType: 'administrative.country', elementType: 'labels', stylers: [{visibility: 'off'}] }
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService,
    private authService: AuthService,
    private modalService: BsModalService,
    private postService: PostService,
    private route: ActivatedRoute,
    private sharedashboardService: SharedashboardService,
    private sanitizer: DomSanitizer
  ){ }

async ngOnInit() {    
    this.route.params.subscribe(params => {
      let data = JSON.parse(atob(params.data));
      this.org = data.org;
      this.proj = data.proj;
      console.log(data);
    });
    await this.loadAllProjects(); 
    await this.getAllProjects();
    await this.getLogframeData();    
    await this.getProjectActivityMetricsData();
    await this.getPosts();
    await this.getProjectActivityMapData();
    await this.getprojectData();
}

openModal(content) {
  this.modalRef = this.modalService.show(content);
}

  toggleAllSelection(input) {
    switch (input) {
      case 'proj':
        if (this.filterProjs) {
          this.proj.options.forEach((item: MatOption) => item.select());
        } else {
          this.proj.options.forEach((item: MatOption) => item.deselect());
        }
      break;
    }
  }

  optionClick(input) {
    let newStatus = true;
    // Switch Between All Inputs
    switch (input) {   
      case 'proj':
        this.proj.options.forEach((item: MatOption) => {
          if (!item.selected) newStatus = false;
        });
        this.filterProjs = newStatus;
      break;
    }
  }

  async loadAllProjects() {
    let projects = await this.projectService.getAllProjectsShareDash(this.org);   
    if(projects) {
      this.loadingProjects = false;
      this.projects = projects;
    }
  }

  async getAllProjects() {
    this.getProjects = null;
    this.getSdgs = null;
    let proj_id = this.filterForm.value;
    let getprojects = await this.projectService.getProjectDetailsShareDash(this.proj)
    if(getprojects) {
      this.getProjects = getprojects.project;
      this.getSdgs = getprojects.sdg;      
    }  
  }

  // get logframe data
  async getLogframeData() {
    let proj_id = this.filterForm.value;
    let log_frame = await this.projectService.getLogframeShareDash({
      proj_id: this.proj
    });
    
    if(log_frame){
      this.log_frame = log_frame;
    }
    
    return true;
  }

  icon = {
    url: 'assets/img/brand/mapmarker.png',    
  }
  // get get project activity metrics
  async getProjectActivityMetricsData() {
    this.activity_metrics=null;
    let proj_id = this.filterForm.value;
    let activity_metrics = await this.projectService.getProjectActivityMetricsShareDash(this.proj);
    
    if(activity_metrics){
      this.activity_metrics = activity_metrics;
    } 
       
    return true;
  }

  async getPosts(loadMore = false) {
    this.userDetails =  this.authService.getUserDetails();
    this.userDetails = await this.authService.getUserDetails();
    // this.org = this.userDetails.user.organization.org_code;
    // this.org = org_code;
    let proj_id = this.filterForm.value;
    let filterData = {
      org: this.org,
      proj: this.proj
    };
    this.POSTs = []; 
    let postData = await this.postService.getAllShareablePostsShareDash(filterData);
    if(postData) {
      if(!loadMore) {
        this.POSTs = postData.posts;        
      } else {
        this.POSTs.concat(postData.posts);
      }
    }    
  }

  async getProjectActivityMapData() {
    this.activity_map_data=null;
    let proj_id = this.filterForm.value;
    let activity_map_data = await this.projectService.getProjectActivityMapShareDash(this.proj);
    if(activity_map_data) {
      this.activity_map_data = activity_map_data;
    }
    this.activity_map_data = activity_map_data;
    return true;
  }

  async getprojectData() {
    console.log('project_share_data');
    this.project_share_data=null;    
    let data = {
      org: this.org,
      proj_id: this.proj,
    }
    let project_share_data = await this.sharedashboardService.getprojecttemplate(data);
    if(project_share_data) {
      this.project_share_data = project_share_data;
      this.video_url = this.sanitizer.bypassSecurityTrustResourceUrl(project_share_data[0].proj_video_url);
    }
    this.project_share_data = project_share_data;
    console.log(project_share_data);
    return true;
  }

  /* async filterdData() {
    this.getAllProjects();
    this.getLogframeData();    
    this.getProjectActivityMetricsData();
    this.getPosts();
    this.getProjectActivityMapData();
  } */

  toBase64(data) {
    return btoa(unescape(encodeURIComponent(JSON.stringify(data))));
  }

}
