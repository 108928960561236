import { Component, OnInit } from '@angular/core';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { NgcCookieConsentService,NgcNoCookieLawEvent,NgcInitializeEvent,NgcStatusChangeEvent } from "ngx-cookieconsent";
import { ToastrService } from 'ngx-toastr';
import { Subscription } from "rxjs";

import { FcmService } from '../../services/fcm.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  showPassword = false;

  loginForm: UntypedFormGroup = this.formBuilder.group({
    email: ['', [
      Validators.required
    ]],
    password: ['', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(25)
    ]]
  });

  // Form States
  loading = false;
  success = false;

  // Form Errors
  errors = {
    email: [
      { type: 'required', message: 'Username / Email is required.' }
    ],
    password: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be atleast 6 characters.' },
      { type: 'maxlength', message: 'Password must not exceed 25 characters.' }
    ]
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private fcmService: FcmService,
    private toastr: ToastrService,
    private ccService: NgcCookieConsentService,
  ) {}

   handleClickSound() {
    let x = <HTMLVideoElement>document.getElementById("myAudio");
    x.play();
  }
  
  ngOnInit(): void {
    //Login Form Init
    this.loginForm = this.formBuilder.group({
      email: ['', [
        Validators.required
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(25)
      ]]
    });

    //Form State Init
    this.loading = false;
    this.success = false;
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
  }

  ngAfterViewInit() {
    if(Notification.permission == 'default') {
      setTimeout(() => {
        // Confirm if deleted all the subsequent files and folders will be removed
        let confirmation = confirm(`Allow Notifications! Please allow notification permission to receive notifications. This will help you stay up to date on your daily activities on Verdentum.`);
        if(confirmation) Notification.requestPermission();
      }, 3000);
    }
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }

  async submitHandler() {
    if(this.loginForm.invalid) {
      this.email.markAsDirty();
      this.password.markAsDirty();
      this.toastr.error('Form contains error. Please check the form again.', 'Error!', {
        timeOut: 5000,
      });
      return;
    }
    
    let loginType = 'user_login';
    this.loading = true;
    this.authService.login(this.loginForm.value, loginType).then(async loggedin => {
      if(!loggedin) {
        this.loading = false;
        return;
      }

      this.fcmService.requestPermission().subscribe((fcmToken) => {
        console.log('Permission granted! Save the token:', fcmToken);
        // Save the token to your server, database, or use it as needed
        this.authService.registerFcmToken(fcmToken);
      }, (error) => {
        console.error('Permission denied or error occurred:', error);
      });

      const user:any = localStorage.getItem("USER_DATA");
      const userDetails = JSON.parse(user);

      if(!userDetails.privacy_policy || userDetails.privacy_policy == 0
      || !userDetails.profile_filled || userDetails.profile_filled == 0
      || !userDetails.org_privacy_policy || userDetails.org_privacy_policy != 1) {
        window.location.reload();
      } else {
        this.authService.trackAuthStatus();
      }
    });
  }
}
