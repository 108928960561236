import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';

import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../../../../environments/environment';
import { SurveyService } from '../../../vadmin/services/survey.service';
import { BeneficiaryService } from '../../../vadmin/services/beneficiary.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  response:any;
  apiLink = apiUrl+'/api/';

  surveyForm:Object;
  surveyDetails:any = {
    title: '',
    subject: '',
    beneficiary: 0,
  };
  surveyData:Object;

  beneficiaries: any = [];
  selectedBenef: any = [];
  benefSettings:IDropdownSettings = {};

  // States
  loading = false;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private surveyService: SurveyService,
    private benefService: BeneficiaryService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      console.log('params', params);
      let dataId = params.dataId;
      this.getSurveyDetails(dataId);
    });
  }

  async getSurveyDetails(dataId) {
    this.http.post(this.apiLink+'invite/get_survey_datail_data', {data_id: dataId}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.surveyDetails = this.response.surveyDetails;
      this.surveyData = this.response.surveyData;

      this.surveyDetails['components'] = JSON.parse(this.surveyDetails['form_fields']);
      this.surveyData['data'] = JSON.parse(this.surveyData['form_data']);

      this.surveyDetails['components'].forEach((element, index) => {
        if(element['key'] && element['key'] == 'draft') this.surveyDetails['components'].splice(index, 1);
      });
      this.surveyDetails['components'].forEach((element, index) => {
        if(element['key'] && element['key'] == 'submit') this.surveyDetails['components'].splice(index, 1);
      });
  
      this.surveyForm = {
        components: this.surveyDetails['components']
      };
  
      this.selectedBenef = [];
      if(this.surveyDetails['beneficiary'] != 0) {
        this.getBenefData();
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }

  async getBenefData() {
    this.loading = true;

    this.benefService.getAllBenefData({id: this.surveyDetails['beneficiary']}).then(benefData => {
      let beneficiaries = [], selected;
      for(let benef of benefData['formdata']) {
        let benefData = JSON.parse(benef.form_data);
        // Format Data
        let formattedData = {}
        formattedData['data_id'] = benef.data_id;
        formattedData['benef_name'] = benefData.benef_firstName + ' ' + (benefData.benef_lastName ? benefData.benef_lastName : '');
        // Append Data into Array
        beneficiaries.push(formattedData);
        
        // Set selected beneficiary
        if(benef.data_id == this.surveyData['beneficiary']) {
          selected = formattedData;
        }
      }
      this.loading = false;

      this.benefSettings = {
        "singleSelection": true,
        "idField": "data_id",
        "textField": "benef_name",
        "allowSearchFilter": true,
        "closeDropDownOnSelection": false
      };
      this.beneficiaries = beneficiaries;
      this.selectedBenef = [selected];
    });
  }

  onItemSelect(event) {}

}
