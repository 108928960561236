import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../../../../environments/environment';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  response:any;
  apiLink = apiUrl+'/api/';

  inviteForm:Object;
  surveyDetails:any = {
    id: 0,
    form_fields: ''
  };

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let invite = atob(params.inviteId);
      this.getInviteDetails(invite);
    });
  }

  async getInviteDetails(invite) {
    this.http.post(this.apiLink+'invite/get_partner_invite', JSON.parse(invite)).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.surveyDetails = this.response.invite.survey;
      this.inviteForm = {
        components: JSON.parse(this.surveyDetails.form_fields)
      };
      if(this.surveyDetails['display_type'] && this.surveyDetails['display_type'].length > 0) {
        this.inviteForm['display'] = this.surveyDetails['display_type'];
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }

  onSubmit(submission: any) {
    console.log(submission); // This will print out the full submission from Form.io API.

    let data = {
      form_id: this.surveyDetails.id,
      form_data: JSON.stringify(submission.data)
    }
    this.http.post(this.apiLink+'invite/collect_partner_survey', data).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }

}
