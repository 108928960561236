import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharepostService } from '../../services/sharepost.service';
import { apiUrl,baseUrl } from '../../../environments/environment';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-share-post',
  templateUrl: './share-post.component.html',
  styleUrls: ['./share-post.component.scss']
})
export class SharePostComponent implements OnInit {

  post_id: any;
  POSTs : any;
  loading : any;
  momentView = moment;
  apiLink = apiUrl;
  baseUrl = baseUrl;
  imageLink : any;

  constructor(
    private zone: NgZone,
    private formBuilder: UntypedFormBuilder,
    private sharepostService: SharepostService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private meta: Meta

  ) { }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      let data = JSON.parse(atob(params.data));
      this.post_id = data.post_id;
    });
    // this.post_id=192
    // Get Post Data
    await this.getPosts();
    // this.meta.updateTag ({property: 'og:image', content: 'http://3.109.148.216/api/uploads/activity/act_image_1647234834_0.jpg'});    
  }

  async getPosts() {
    this.loading = true;
    this.POSTs = [];
    let post_id = this.post_id;
    let postData = await this.sharepostService.getSharePost(post_id);
    if(postData) {
      this.POSTs = postData.posts;
      for (let index = 0; index < this.POSTs.length; index++) {
        const element = this.POSTs[index];
        const imgName = element.images[0].image_name;
        this.imageLink = this.apiLink +'/uploads/'+ imgName;
        // console.log('imageLink',this.imageLink);
      }
    }
    
    this.loading = false;
  }
}
