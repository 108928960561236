import { Component, OnInit} from '@angular/core';
import { navItems } from './_nav';

import { AuthService } from '../services/auth.service';
import { SocketService } from './services/socket.service';

import { apiUrl } from '../../environments/environment';

@Component({
  selector: 'app-vadmin',
  templateUrl: './vadmin.component.html',
  styleUrls: ['./vadmin.component.scss']
})
export class VadminComponent implements OnInit {

  public currentYear = new Date();
  public sidebarMinimized = false;
  public navItems = navItems;

  name = '';
  userDetails:any;
  apiLink = apiUrl;

  constructor(
    public authService: AuthService,
    public socketSercie: SocketService) { }

  ngOnInit(): void {
    this.getUserDetails();    
  }

  async getUserDetails() {
    this.userDetails = await this.authService.getUserDetails();
    
    // Check if admin is still logged in
    if(this.userDetails.relogin && this.userDetails.relogin == 1) {
      await this.authService.removeUserDetails();
      this.authService.trackAuthStatus();
    } else if(this.userDetails.type != 'vadmin') {
      this.authService.trackAuthStatus();
    }
    
    this.name = this.userDetails.user.first_name;
    this.name += this.userDetails.user.last_name ? ' '+this.userDetails.user.last_name : '';
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  async logout() {
    await this.authService.removeUserDetails();
    this.authService.trackAuthStatus();
  }

}
