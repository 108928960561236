// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false
};

export const firebaseConfig = {
  apiKey: "AIzaSyBwkXC88pxkByXov6MqMlLJhgLOeK7hycU",
  authDomain: "eminent-helix-365916.firebaseapp.com",
  projectId: "eminent-helix-365916",
  storageBucket: "eminent-helix-365916.appspot.com",
  messagingSenderId: "501388281391",
  appId: "1:501388281391:web:35f8c9e7210f729776a8da",
  measurementId: "G-VENY6S6GEW"
};
export const firebaseVapId = "BBOHJxGNw5wEeHVLUCcD9n_D_AtC5Y5mutg8vT9IMFgZH608gNM1TrZo-GEqF74hHazrNriZRwnW8bLS9NYbCdE";

// // For Local
// export const reportBuilder = 'http://localhost/verdentum_mean_client/report_builder/';
// export const regression = 'http://localhost/verdentum_mean_client/report_builder/statistics/';
// export const googleApiKey = 'AIzaSyC4NfY_qeaoPpske4Dx3ijbZARFp60Wbrc';
// export const apiUrl = 'http://localhost:3000';
// export const baseUrl = 'http://localhost:4200';
// export const socketIoConfig = {
//   url: 'http://localhost:3000',
//   options: {}
// };

// // For Test Server
// export const reportBuilder = 'http://3.109.148.216/report_builder/';
// export const regression = 'http://3.109.148.216/report_builder/statistics/';
// export const googleApiKey = 'AIzaSyC4NfY_qeaoPpske4Dx3ijbZARFp60Wbrc';
// export const apiUrl = 'http://3.109.148.216/api';
// export const baseUrl = 'http://3.109.148.216';
// export const socketIoConfig = {
//   url: 'http://3.109.148.216',
//   options: { path: '/api/socket.io' }
// };

// For Sandbox Server
export const reportBuilder = 'https://sandbox.verdentum.com/report_builder/';
export const regression = 'https://sandbox.verdentum.com/report_builder/statistics/';
export const googleApiKey = 'AIzaSyC4NfY_qeaoPpske4Dx3ijbZARFp60Wbrc';
export const apiUrl = 'https://sandbox.verdentum.com/api';
export const baseUrl = 'https://sandbox.verdentum.com';
export const socketIoConfig = {
  url: 'https://sandbox.verdentum.com',
  options: { path: '/api/socket.io' }
};

export const maxFilesize = {
  label: '2MB',
  value: 2097152
};