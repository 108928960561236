import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  
  // myRecaptcha = new FormControl(false);
  forgotForm: UntypedFormGroup = this.formBuilder.group({
    email: ['', [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
    ]],
    myRecaptcha: [false, [
      Validators.required,
    ]],
  });

  // Form Errors
  errors = {
    email: [
      { type: 'required', message: ' Email is required.' },
      { type: 'pattern', message: ' Invalid Email format.' }
    ],
    myRecaptcha: [
      { type: 'invalidRecaptcha', message: ' Captcha is required.' }
    ],
  }

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!')
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA')
  }

  loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.forgotForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]],
      myRecaptcha: [false, [
        Validators.required,
      ]],
      
    });
  }

  get email() {
    return this.forgotForm.get('email');
  }
  get myRecaptcha(){
    return this.forgotForm.get('myRecaptcha');
  }

  async onSubmit() {
    if(this.forgotForm.invalid) {
      this.email.markAsDirty();
      this.myRecaptcha.markAsDirty();
      this.toastr.error('Form contains error. Please check the form again.', 'Error!', {
        timeOut: 5000,
      });
      return;
    }
    this.loading = true;
    let mail = await this.authService.forgotPassword(this.email.value);
    this.loading = false;
    if(mail) window.location.reload();
  }

}
