import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class WithoutAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }
  
  canActivate(): Promise<boolean> | boolean {
    if(!this.authService.isAuthenticated()) {
      return true;
    } else {
      return this.authService.getUserDetails().then(userDetails => {
        if(userDetails.type == 'vadmin') {
          this.router.navigate(['vadmin']);
          return false;
        } else if(userDetails.type == 'user') {
          if(userDetails.user.privacy_policy == 0) {
            // Redirect to Verdentum Privacy Policy Page
            this.router.navigate(['intro/verdentum_privacy']);
          } else if(userDetails.user.org_privacy_policy != 1) {
            // Redirect to Organization Privacy Policy Page
            this.router.navigate(['intro/org_privacy']);
          } else {
            // Redirect to User Page
            this.router.navigate(['user']);
          }
          return false;
        }

        return true;
      });
    }
  }
  
}
