import { Component, OnInit, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../../../environments/environment';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import * as moment from 'moment';
import * as echarts from 'echarts';

@Component({
  selector: 'app-pip',
  templateUrl: './pip.component.html',
  styleUrls: ['./pip.component.scss']
})
export class PipComponent implements OnInit {

  response:any;
  apiUrl = apiUrl;
  apiLink = apiUrl+'/api/';

  data = {
    duration: '0 Days',
    total_modules: 0
  }
  params:any;
  urlData:any;
  pipData:any;
  pipPlan:any[] = [];
  resources:any[] = [];
  groupedPlan:any[] = [];
  stakeholderDetails:any;

  momentView = moment;
  modalRef: BsModalRef;
  ganttChartInstance:any;
  public Editor = ClassicEditor;

  loading = true;
  loadingApproval = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.route.params.subscribe((params:any) => {
      this.params = params;
      this.urlData = JSON.parse(decodeURIComponent(escape(atob(params.pipData))));
      this.getPipDetails(params);
    });
  }

  async getPipDetails(params) {
    this.pipData = null;
    
    this.http.post(this.apiLink+'pip/get_data_detail_noauth', {pip_data: params.pipData}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        this.loading = false;
        return false;
      }

      this.data = {
        duration: '0 Days',
        total_modules: 0
      };
      this.pipData = this.response.details;
      this.pipPlan = this.response.action_plan;
      this.stakeholderDetails = this.response.stakeholder;
      this.groupPlans();

      // Get all distinct resources
      let distinctResId = [];
      for (const plan of this.pipPlan) {
        if(plan.reportingManager && plan.reportingManager.reg_id && !distinctResId.includes(plan.reportingManager.reg_id)) {
          distinctResId.push(plan.reportingManager.reg_id);
          this.resources.push(plan.reportingManager);
        }
        for (let assign of plan.assignTo) {
          if(assign && assign.reg_id && !distinctResId.includes(assign.reg_id)) {
            distinctResId.push(assign.reg_id);
            this.resources.push(assign);
          }
        }
      }
      
      // Get total project duration
      let start = moment(this.pipData['Project start date']),
      end = moment(this.pipData['Project end date']);
      // Calculate the difference in months and days
      var duration = moment.duration(end.diff(start));
      // Get the number of months and remaining days
      var months = end.diff(start, 'months');
      start.add(months, 'months');
      var days = end.diff(start, 'days');
      this.data.duration = months + " Months, " + days + " Days";

      // Get total module count
      if(this.pipData['User Management Module'] == 'true') this.data.total_modules++;
      if(this.pipData['Program Management Module'] == 'true') this.data.total_modules++;
      if(this.pipData['Partner Management Module'] == 'true') this.data.total_modules++;
      if(this.pipData['Activity Management Module'] == 'true') this.data.total_modules++;
      if(this.pipData['Task Management Module'] == 'true') this.data.total_modules++;
      if(this.pipData['Survey Management Module'] == 'true') this.data.total_modules++;
      if(this.pipData['Beneficiary Management Module'] == 'true') this.data.total_modules++;
      if(this.pipData['Reporting Module'] == 'true') this.data.total_modules++;
      if(this.pipData['CRM Module'] == 'true') this.data.total_modules++;

      if(this.pipData['Stakeholders'] && typeof this.pipData['Stakeholders'] == 'string') {
        this.pipData['Stakeholders'] = JSON.parse(this.pipData['Stakeholders']);
      }

      var data = [];
      var categories = [];
      var colors = ['#7b9ce1', '#e17b9c', '#9ce17b', '#e1e17b']; // Add more colors as needed
      this.pipData['milestoneInformation'].forEach(function(milestone, milestoneIndex) {
        milestone.subMilestoneDeatils.forEach(function(subMilestone, subMilestoneIndex) {
          subMilestone.taskDetails.forEach(function(task, taskIndex) {
            data.push({
              name: task.taskName,
              value: [milestoneIndex, new Date(task.startDate1), new Date(task.endDate1), taskIndex],
              itemStyle: {
                normal: {
                  color: colors[subMilestoneIndex % colors.length] // Assign color dynamically
                }
              }
            });
          });
        });
        categories.push(milestone.milestoneName)
      });
      setTimeout(() => {
        this.loadGanttChart(data, categories, colors);
      }, 500);
      
      this.loading = false;
    }).catch(err => {
      console.log(err);
      this.loading = false;
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }
  groupPlans() {
    const groupedByMilestone = this.groupBy(this.pipPlan, 'milestoneName');
  
    this.groupedPlan = Object.keys(groupedByMilestone).map((mile) => {
      return {
        milestone: mile,
        subMilesotnes: this.groupBy(groupedByMilestone[mile], 'subMilestoneName')
      };
    });
  }
  groupBy(arr: any[], key: string) {
    return arr.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  }
  getTotalTaskInMilestone(mile) {
    return this.pipPlan.filter((plan:any) => {
      return plan.milestoneName == mile;
    }).length;
  }

  loadGanttChart(data, categories, colors) {
    let chartDom = document.getElementById('ganttChart');
    this.ganttChartInstance = echarts.init(chartDom);

    let option = {
      tooltip: {
        formatter: function (params) {
          return params.marker + params.name + ': ' + params.value[3] + ' ms';
        }
      },
      title: {
        text: 'Timeline for action plan',
        left: 'center'
      },
      dataZoom: [
        {
          type: 'slider',
          filterMode: 'weakFilter',
          showDataShadow: false,
          top: 500,
          labelFormatter: ''
        },
        {
          type: 'inside',
          filterMode: 'weakFilter'
        }
      ],
      grid: {
        left: '1%',
        bottom: '5%',
        // height: 400,
        containLabel: true
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          formatter: function (val) {
            return echarts.format.formatTime('yyyy-MM-dd', val);
          }
        }
      },
      yAxis: {
        type: 'category',
        data: categories,
        inverse: true,
        axisLabel: {
          interval: 0,
          formatter: function (value:any) {
            let words = value.split(' ');
            let lines = [];
            for (let i = 0; i < words.length; i += 2) {
              lines.push(words.slice(i, i + 2).join(' '));
            }
            return lines.join('\n');
          }
        }
      },
      series: [{
        type: 'custom',
        coordinateSystem: 'cartesian2d', // Set coordinate system to cartesian2d
        renderItem: function(params, api) {
          var categoryIndex = api.value(0);
          var start = api.coord([api.value(1), categoryIndex]);
          var end = api.coord([api.value(2), categoryIndex]);
          var height = api.size([0, 1])[1] * 0.6;
          var rectShape = echarts.graphic.clipRectByRect({
            x: start[0],
            y: start[1] - height / 2,
            width: end[0] - start[0],
            height: height
          },
          {
            x: params.coordSys.x,
            y: params.coordSys.y,
            width: params.coordSys.width,
            height: params.coordSys.height
          });
          return (
            rectShape && {
              type: 'rect',
              transition: ['shape'],
              shape: rectShape,
              style: api.style()
            }
          );
        },
        itemStyle: {
          opacity: 0.8
        },
        encode: {
          x: [1, 2],
          y: 0
        },
        data: data
      }]
    };
    console.log(option);
    option && this.ganttChartInstance.setOption(option);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (this.ganttChartInstance) {
      this.ganttChartInstance.resize();
    }
  }

  openModal(content) {
    this.modalRef = this.modalService.show(content);
  }
  async approvePlan() {
    this.loadingApproval = true;
    this.http.post(this.apiLink+'pip/update_paln_approval', {status: 1, pip_data: this.params.pipData}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        this.loadingApproval = false;
        return false;
      }

      this.stakeholderDetails['approved'] = 1;
      this.loadingApproval = false;
    }).catch(err => {
      console.log(err);
      this.loadingApproval = false;
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }
  async rejectPlan() {
    this.loadingApproval = true;
    this.http.post(this.apiLink+'pip/update_paln_approval', {status: 0, comment: this.stakeholderDetails.comment, pip_data: this.params.pipData}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        this.loadingApproval = false;
        return false;
      }

      this.stakeholderDetails['approved'] = 0;
      this.loadingApproval = false;
      this.modalRef.hide();
    }).catch(err => {
      console.log(err);
      this.loadingApproval = false;
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }

}
