import { Injectable } from '@angular/core';

import { AuthService } from '../services/auth.service';

import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WithAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    if(!this.authService.isAuthenticated()) {
      localStorage.setItem('REDIRECT_URL', state.url);
      this.router.navigate(['login']);
      return false;
    } else {
      return this.authService.getUserDetails().then(userDetails => {
        if(userDetails.type == 'user') {
          if(userDetails.user.privacy_policy == 0) {
            // Redirect to Verdentum Privacy Policy Page
            this.router.navigate(['intro/verdentum_privacy']);
          } else if(userDetails.user.org_privacy_policy != 1) {
            // Redirect to Organization Privacy Policy Page
            this.router.navigate(['intro/org_privacy']);
          }
        }
        return true;
      });
    }
    return true;
  }
}
