import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { getToken, deleteToken, getMessaging, Messaging } from '@angular/fire/messaging';

import { firebaseConfig, firebaseVapId } from '../../environments/environment';

import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private angularFireMessaging: AngularFireMessaging
  ) {
    this.angularFireMessaging.messages.subscribe(
      (message) => {
        console.log("New message received: ", message);
        this.currentMessage.next(message);
      }
    );
  }

  requestPermission() {
    return this.angularFireMessaging.requestToken;
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((message) => {
      console.log("New message received: ", message);
      this.currentMessage.next(message);
    });
  }

  async unregisterToken() {
    let fcmToken = localStorage.getItem('fcmToken');
    if(!fcmToken) return true;
    
    this.angularFireMessaging.deleteToken(fcmToken).subscribe(() => {
      console.log('Token deleted from firebase');
    }, (error) => {
      console.error('Unable to delete token from firebase', error);
    });
  }
}
