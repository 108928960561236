import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { apiUrl } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  response: any;
  apiLink = apiUrl + '/api/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  ///
  async addTheme(data) {
    let userDetails = await this.authService.getUserDetails(),
      token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'theme/add', {data}, { headers: headers }).toPromise().then(response => {

      this.response = response;
      if (this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }
  async getuserTheme(data) {
    let userDetails = await this.authService.getUserDetails(),
      token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'theme/get', {data}, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.theme;
    }).catch(err => {
      console.log(err);
      if(err?.statusText != "Unknown Error") {
        this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
          timeOut: 5000,
        });
      }
      return false;
    });
  }
}
