import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { apiUrl } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  response:any;
  apiLink = apiUrl+'/api/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  //===========get all posts=================
  async getAllPosts(filter = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/get_all', {filter: filter}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return {
        sdgs: this.response.sdgs,
        posts: this.response.posts,
        projects: this.response.projects,
        graphs: this.response.graphs
      };
    });
  }
  //===========get all comments=================
  async getAllComments(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/get_all_comment', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.comments;
    });
  }
  //===========get all supports=================
  async getAllSupports(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/get_all_support', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.supports;
    });
  }

  //===========upload post=================
  async uploadPost(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/post', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success('Post uploaded successfully.', 'Success!', {
          timeOut: 5000,
        });
      }
      return this.response.new_post;
    });
  }

  //===========post comment=================
  async postComment(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/post_comment', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.count;
    });
  }

  //===========post support=================
  async postSupport(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/post_support', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.count;
    });
  }

  //===========post Share=================
  async postShare(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/post_share', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.count;
    });
  }

  //===========delete post=================
  async deletePost(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/post_delete', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return true;
    });
  }
  //===========get all posts=================
  async getAllShareablePosts(filter = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/get_all_sharepost', {filter: filter}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return {
        sdgs: this.response.sdgs,
        posts: this.response.posts,
        projects: this.response.projects,
        graphs: this.response.graphs
      };
    });
  }

  //===========get all posts for public sharebale dashboard=================
  async getAllShareablePostsShareDash(filter = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'lookup/get_all_sharepost_share_dash', {filter: filter}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return {
        sdgs: this.response.sdgs,
        posts: this.response.posts,
        projects: this.response.projects,
        graphs: this.response.graphs
      };
    });
  }
  //===========delete shared post=================
  async deleteSharedPost(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/shared_post_delete', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return true;
    });
  }
  
  //===========get all approved posts=================
  async getAllApprovedPosts(filter = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/get_all_approvedpost', {filter: filter}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return {
        sdgs: this.response.sdgs,
        posts: this.response.posts,
        projects: this.response.projects,
        graphs: this.response.graphs
      };
    });
  }

  //===========get all approved photos=================
  async getAllApprovedPhoto(filter = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/get_all_approvedphoto', {filter: filter}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return {
        sdgs: this.response.sdgs,
        posts: this.response.posts,
        projects: this.response.projects,
        graphs: this.response.graphs
      };
    });
  }

  //===========delete post permission as per data_management=================
  async deletePostPermission(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/post_del_permission', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return true;
    });
  }

  async deletePhotoPermission(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/photo_del_permission', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return true;
    });
  }

  /////===========Approval post by org_admin ====/////// 
  async postapprove(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/photo_org_permission', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return true;
    });
  }

  // ============ Get share post link ===========
  async getSharePostUrl(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'post/share_post', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.link;
    });
  }
}
