import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { apiUrl } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SharedashboardService {

  response:any;
  apiLink = apiUrl+'/api/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  async getHeader() {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return headers;
  }

  //===============Update project objt from share_dashboard===================//
  async updateprojectobj(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'sharedash/project_editdetails', {data}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.response;
     
    });
  }

  //===============Update project objt from share_dashboard===================//
  async getprojecttemplate(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'publicsharedash/project_get_template',{data}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.response.project;
     
    });
  }
  //=======post delete as per permission===//
  async deactivate(id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'sharedash/deactivate_sharedash', {id}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return true;
    });
  }
  //===share post delete
  async deletePostshare(data) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'sharedash/post_share_delete', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return true;
    });
  }






  /* New dashboard methods
  ************************/

  // Save dashboard
  async saveDashboard(details) {
    let headers = await this.getHeader();

    return this.http.post(this.apiLink+'sharedash/save_dashboard', details, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return this.response.dashboardId;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }
  // Save Dashboard State
  async saveDashboardState(details) {
    let headers = await this.getHeader();

    return this.http.post(this.apiLink+'sharedash/save_dashboard_state', details, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }
  
  // Get dashboard data
  async getDashboardData(dashboardId) {
    let headers = await this.getHeader();

    return this.http.post(this.apiLink+'sharedash/get_dashboard_data', {id: dashboardId}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.response.dashboardDetails;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  // Get all saved and accessible dashboards
  async getAllDashboards() {
    let headers = await this.getHeader();

    return this.http.post(this.apiLink+'sharedash/get_dashboards', {}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      
      return this.response.dashboards;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  async changePublicStatus(dashboard_id) {
    let headers = await this.getHeader();

    return this.http.post(this.apiLink+'sharedash/publish_unpublish_dashboard', {id: dashboard_id}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return this.response.is_public;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }
}
