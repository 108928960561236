import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../../../environments/environment';

@Component({
  selector: 'app-rfq',
  templateUrl: './rfq.component.html',
  styleUrls: ['./rfq.component.scss']
})
export class RfqComponent implements OnInit {

  response:any;
  apiUrl = apiUrl;
  apiLink = apiUrl+'/api/';

  gross = 0.00;
  params:any;
  urlData:any;
  rfqDetails:any = {
    customer_name: '',
    customer_phone: '',
    customer_address: ''
  };
  products:any[] = [];

  loading = true;
  quotationSent = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.route.params.subscribe((params:any) => {
      this.params = params;
      this.urlData = JSON.parse(decodeURIComponent(escape(atob(params.rfqData))));
      this.getProductDetails(params);
    });
  }

  async getProductDetails(params) {
    this.products = [];
    
    this.http.post(this.apiLink+'inventory/get_rfq_vendor_data_noauth', {rfq_data: params.rfqData}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        this.loading = false;
        return false;
      }

      this.rfqDetails = this.response.rfq;
      
      this.products = this.response.products;
      if(this.products[0].status == 2) this.quotationSent = true;

      this.calculateTotal();
      
      this.loading = false;
    }).catch(err => {
      console.log(err);
      this.loading = false;
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }

  calculateTotal() {
    let gross = 0.00;
    for (const item of this.products) {
      gross = gross + (item.amount ? parseFloat(item.amount) : 0.00);
    }
    this.gross = parseFloat(gross.toFixed(2));
    if(isNaN(this.gross)) this.gross = 0.00;
  }

  async sendQuotation() {
    for (const item of this.products) {
      if(!item.rate || isNaN(item.rate)) {
        this.toastr.error('Please provide price per unit for all the listed items.', 'Error!', {
          timeOut: 5000,
        });
        return;
      }
    }
    
    let request = {
      products: this.products,
      rfqDetails: this.rfqDetails
    };
    this.http.post(this.apiLink+'inventory/update_rfq_vendor_data_noauth', request).toPromise().then(response => {
      this.response = response;
      
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        this.loading = false;
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      for (const key in this.products) {
        this.products[key]['status'] = 2;
      }
      this.quotationSent = true;
      this.loading = false;
    }).catch(err => {
      console.log(err);
      this.loading = false;
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }

}
