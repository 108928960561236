import { AppModule } from './app/app.module';
// import {LicenseManager} from '@ag-grid-enterprise/core';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// LicenseManager.setLicenseKey('Arkin_HIS_3Devs28_March_2020__MTU4NTM1MzYwMDAwMA==ce1eed0a6018afcf2a06b081b503f06a');

if (environment.production) {
  enableProdMode();
}

// register firebase-messaging-sw.js service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then((registration) => {
    console.log('Firebase messaging service worker registered', registration);
  }).catch((err) => {
    console.error('Service worker registration failed', err);
  });
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  useJit: true,
  preserveWhitespaces: true
})
  .catch(err => console.log(err));
