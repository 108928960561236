import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';
import { apiUrl, baseUrl } from '../../../environments/environment';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verdentum-privacy-mobile',
  templateUrl: './verdentum-privacy-mobile.component.html',
  styleUrls: ['./verdentum-privacy-mobile.component.scss']
})
export class VerdentumPrivacyMobileComponent implements OnInit {

  response:any;
  userDetails:any;
  apiLink = apiUrl+'/api/';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  updatePrivacy() {
    this.router.navigate(['intro/verdentum_privacy_mobile_close']);
  }

}
