import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { apiUrl } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharepostService {

  response:any;
  apiLink = apiUrl+'/api/';

  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService
  ) { }

  //===========get all posts=================
  async getSharePost(post_id) {
    return this.http.post(this.apiLink+'lookup/get_share_post', {post_id: post_id}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return {
        sdgs: this.response.sdgs,
        posts: this.response.posts,
        projects: this.response.projects,
        graphs: this.response.graphs
      };
    });
  }

}
