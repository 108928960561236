import { Component, OnInit,EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormioRefreshValue } from 'angular-formio/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../../../environments/environment';
import { RfpService } from '../../vadmin/services/rfp.service';


@Component({
  selector: 'app-rfp-form',
  templateUrl: './rfp-form.component.html',
  styleUrls: ['./rfp-form.component.scss']
})
export class RfpFormComponent implements OnInit {

  response:any;
  apiLink = apiUrl+'/api/';
  rfpForm:Object;
  rfpDetails:Object;
  // States
  loading = false;
  visible = true;
  outOfDate : any;
  beforeOfDate: any;

  public refreshForm: EventEmitter<FormioRefreshValue> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private rfpService: RfpService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let publicid = (atob(params.publicid));
      this.getrfpeDetails(publicid);
    });
  }

  //get rfp from for publicID
  async getrfpeDetails(publicid) {
    let public_id = publicid
    this.http.post(this.apiLink+'lookup/get_rfp_forms',{public_id : public_id}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      
      this.rfpDetails = this.response.rfps;
      let sDate = Date.parse(this.rfpDetails[0].start_date);
      let eDate = Date.parse(this.rfpDetails[0].end_date);
      let todayDate = new Date().toISOString().slice(0, 10);
      var tDate = Date.parse(todayDate);
      /* if(tDate <= sDate) {
        this.toastr.error(this.response.msg, 'Form Submission havent start yet!', {
          timeOut: 500000,
        });
        return false;
      }else if(tDate >= eDate) {
        this.toastr.error(this.response.msg, 'Form Submission date ended!', {
          timeOut: 500000,
        });
        return false;
      } */
      if(tDate < sDate) {
        this.visible = false;
        this.beforeOfDate = true;
      }else if(tDate > eDate) {
        this.visible = false;
        this.outOfDate = true;
      }

      this.rfpForm = {
        components: JSON.parse(this.rfpDetails[0].form_fields)
      };
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }

  //submit rfp from 
  onSubmit(submission: any) {
    let data = {
      form_id: this.rfpDetails[0].id,
      form_data: JSON.stringify(submission.data),
    }
    this.http.post(this.apiLink+'lookup/collect_public_rfp', data).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
    });
  }  

}
