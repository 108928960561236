import { Component, OnInit } from '@angular/core';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-login',
  templateUrl: 'admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  
  loginForm: UntypedFormGroup = this.formBuilder.group({
    email: ['', [
      Validators.required
    ]],
    password: ['', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(25)
    ]]
  });

  // Form States
  loading = false;
  success = false;

  // Form Errors
  errors = {
    email: [
      { type: 'required', message: 'Username / Email is required.' }
    ],
    password: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be atleast 6 characters.' },
      { type: 'maxlength', message: 'Password must not exceed 25 characters.' }
    ]
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    //Login Form Init
    this.loginForm = this.formBuilder.group({
      email: ['', [
        Validators.required
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(25)
      ]]
    });

    //Form State Init
    this.loading = false;
    this.success = false;
  }

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }

  submitHandler() {
    if(this.loginForm.invalid) {
      this.email.markAsDirty();
      this.password.markAsDirty();
      this.toastr.error('Form contains error. Please check the form again.', 'Error!', {
        timeOut: 5000,
      });
      return;
    }
    
    let loginType = 'admin_login';
    this.loading = true;
    this.authService.login(this.loginForm.value, loginType).then(_loggedin => {
      if(!_loggedin) this.loading = false;
      else window.location.reload();
    });
  }
}
