import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../../services/auth.service';
import { apiUrl } from '../../../environments/environment';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  response:any;
  userDetails:any;
  apiLink = apiUrl+'/api/';

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.getUserDetails();
  }

  ngOnInit(): void {
  }

  async getUserDetails() {
    let userDetails = await this.authService.getUserDetails();
    if(userDetails) this.userDetails = userDetails;
    
    // Check if user profile filled already accepted
    if(this.userDetails.user.profile_filled == 1) {
      this.router.navigate(['user/post']);
    }
  }

  async updatePrivacy() {
    console.log('user');
    this.router.navigate(['/user/post']);
  }

  async logout() {
    await this.authService.removeUserDetails();
    this.authService.trackAuthStatus();
  }

}
