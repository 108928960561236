import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../../services/auth.service';
import { apiUrl } from '../../../environments/environment';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verdentum-privacy',
  templateUrl: './verdentum-privacy.component.html',
  styleUrls: ['./verdentum-privacy.component.scss']
})
export class VerdentumPrivacyComponent implements OnInit {

  response:any;
  userDetails:any;
  apiLink = apiUrl+'/api/';

  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    this.getUserDetails();
  }

  ngOnInit(): void {
  }

  async getUserDetails() {
    let userDetails = await this.authService.getUserDetails();
    if(userDetails) this.userDetails = userDetails;

    // Check if verdentum privacy policy is accepted
    if(this.userDetails.user.privacy_policy == 1) {
      await this.authService.setUserDetails({
        type: this.userDetails.type,
        token: this.userDetails.token,
        user: [this.userDetails.user]
      });
      this.router.navigate(['intro/org_privacy']);
    }
  }

  async updatePrivacy() {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'usermgmt/update_vpp', {}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      this.userDetails.user.privacy_policy = 1;
      this.authService.setUserDetails({
        type: this.userDetails.type,
        token: this.userDetails.token,
        user: [this.userDetails.user]
      });
      this.router.navigate(['intro/org_privacy']);
    });
  }

  async logout() {
    await this.authService.removeUserDetails();
    this.authService.trackAuthStatus();
  }

}
