import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TypeofPipe } from './typeof/typeof.pipe';
import { SafehtmlPipe } from './safehtml/safehtml.pipe';
import { PaginationComponent } from './pagination/pagination.component';


@NgModule({
  declarations: [
    TypeofPipe,
    SafehtmlPipe,
    PaginationComponent,
  ],
  imports: [
    FormsModule,
    CommonModule
  ],
  exports: [
    TypeofPipe,
    SafehtmlPipe,
    PaginationComponent
  ]
})
export class UtilityModule { }
