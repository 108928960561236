import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../../../../environments/environment';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  showPassword = false;
  showConfirmPassword = false;
  response:any;
  key:any;
  apiLink = apiUrl+'/api/';
  
  // Password details edit form
  resetForm: UntypedFormGroup = this.formBuilder.group({
    password: ['', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(25),
      Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=.*[$@$!%*?&])(?=[^A-Z]*[A-Z]).{6,25}$/)
    ]],
    confirmPassword: ['', [
      Validators.required
    ]]
  });

  // Form Errors
  passwordErrors = {
    password: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password must be atleast 6 characters.' },
      { type: 'maxlength', message: 'Password must not exceed 25 characters.' },
      { type: 'pattern', message: 'Passwords must be of 6 to 25 characters long. Password should include altest 1 uppercase letter, 1 special character(@, #, $, %, &,) and 1 number.'}
    ],
    confirmPassword: [
      { type: 'required', message: 'Confirm password is required.' }
    ]
  };

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.key = params.key;
    });
  }

  onSubmit() {
    if(this.resetForm.invalid) {
      this.password.markAsDirty();
      this.confirmPassword.markAsDirty();
      this.toastr.error('Form contains error. Please check the form again.', 'Error!', {
        timeOut: 5000,
      });
      return;
    }
    let key = this.key;
    let pass = this.password.value;
    let cpass = this.confirmPassword.value;
    if(pass.length != 0 && pass == cpass){
      this.http.post(this.apiLink+'auth/reset_password',{key:key, pass:pass, cpass:cpass}).toPromise().then(response => {
        this.response = response;
        if(this.response.status == 0) {
          this.toastr.error(this.response.msg, 'Error!', {
            timeOut: 5000,
          });
        } else {
          this.toastr.success(this.response.msg, 'Success!', {
            timeOut: 5000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } 
      }).catch(err => {
        console.log(err);
        this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
          timeOut: 5000,
        });
      });
    } else {
      this.toastr.error('Please check form fields', 'Error!', {
        timeOut: 5000,
      });
    }
  }

  get password() {
    return this.resetForm.get('password');
  }
  get confirmPassword() {
    return this.resetForm.get('confirmPassword');
  }

}
