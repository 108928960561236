import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppFooterModule,
  AppHeaderModule,
  AppSidebarModule,
} from '@coreui/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';

// import { AgGridModule } from 'ag-grid-angular';
import { AppComponent } from './app.component';
// Import routing module
import { AppRoutingModule } from './app.routing';
// import { VadminModule } from './vadmin/vadmin.module';
// import { UserModule } from './user/user.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ChartsModule } from 'ng2-charts';
import { FormioModule } from 'angular-formio';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './public/login/login.component';
import { MatInputModule } from '@angular/material/input';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgModule } from '@angular/core';
import { P404Component } from './vadmin/error/404.component';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll'; //InfiniteScroll lazy loading
import { MatSelectModule } from "@angular/material/select";
import { AgmCoreModule } from '@agm/core';
import { TabViewModule } from 'primeng/tabview';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { GoogleMapsModule } from '@angular/google-maps'
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MatMenuModule } from "@angular/material/menu";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { UtilityModule } from "./utility/utility.module";
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmMarkerSpiderModule } from 'agm-spiderfier1';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

// Import containers
import { VadminComponent } from './vadmin/vadmin.component';
import { WithAuthGuard } from './guards/with-auth.guard';
import { WithoutAuthGuard } from './guards/without-auth.guard';
import { CanCompDeactivateGuard } from './guards/can-comp-deactivate.guard';

import { RecaptchaModule } from 'angular-google-recaptcha';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// Import ServiceWorker
import { ServiceWorkerModule } from '@angular/service-worker';
// Import firebase functions from SDK
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

import { googleApiKey, baseUrl, socketIoConfig, firebaseConfig, firebaseVapId } from '../environments/environment';


import { UserComponent } from './user/user.component';
import { AdminLoginComponent } from './public/admin-login/admin-login.component';

import { IntroComponent } from './intro/intro.component';
import { VerdentumPrivacyMobileComponent } from './intro/verdentum-privacy-mobile/verdentum-privacy-mobile.component';
import { VerdentumPrivacyComponent } from './intro/verdentum-privacy/verdentum-privacy.component';
import { OrgPrivacyComponent } from './intro/org-privacy/org-privacy.component';
import { VideoComponent } from './intro/video/video.component';

import { InviteComponent } from './public/invite/invite.component';
import { GeneralComponent as InviteGeneralComponent } from './public/invite/general/general.component';
import { PartnerComponent as InvitePartnerComponent } from './public/invite/partner/partner.component';
import { SuccessComponent as InviteSuccessComponent } from './public/invite/success/success.component';

import { ViewdataComponent } from './public/viewdata/viewdata.component';
import { SurveyComponent as ViewSurveyComponent } from './public/viewdata/survey/survey.component';

import { MatDialogModule } from "@angular/material/dialog";
import { SignupComponent } from './public/signup/signup.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { ForgotPasswordComponent } from './public/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './public/login/reset-password/reset-password.component';
import { PublicShareableDashboardComponent } from './public/public-shareable-dashboard/public-shareable-dashboard.component';
import { SharePostComponent } from './public/share-post/share-post.component';
import { RfpFormComponent } from './public/rfp-form/rfp-form.component';
import { PipComponent } from './public/pip/pip.component';
import { RfqComponent } from './public/rfq/rfq.component';

// import { HighchartsChartComponent } from 'highcharts-angular';

const APP_CONTAINERS = [
  VadminComponent,
  UserComponent
];

const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": `${baseUrl}`
  },
  "position": "bottom",
  "theme": "edgeless",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#f1d600",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "This website uses cookies to ensure you get the best experience on our website.",
    "dismiss": "Accept all",
    "deny": "Refuse cookies",
    "link": "Cookie Policy",
    "href": `${baseUrl}/#/intro/verdentum_privacy/`,
    "policy": "Cookie Policy"
  }
}




const socketConfig: SocketIoConfig = socketIoConfig;

@NgModule({
  imports: [
    BrowserModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: true,
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireMessagingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // VadminModule,
    // UserModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    SocketIoModule.forRoot(socketConfig),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule ,
    FormioModule,
    MatInputModule,
    CKEditorModule,
    // AgGridModule.withComponents([]),
    MatDialogModule,
    TourMatMenuModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    InfiniteScrollModule,
    RecaptchaModule.forRoot({siteKey:'6Le3ywAVAAAAALIWFcUM2EtZrB49F3wpg7LhCDpM',}),
    MatSelectModule,
    AgmCoreModule.forRoot({
      apiKey: googleApiKey,
      libraries: ['places']
    }),
    TabViewModule,
    ProgressbarModule,
    GoogleMapsModule,
    CarouselModule,
    MatMenuModule,
    CollapseModule.forRoot(),
    UtilityModule,
    AgmSnazzyInfoWindowModule,
    AgmJsMarkerClustererModule,
    AgmMarkerSpiderModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    LoginComponent,
    AdminLoginComponent,
    SignupComponent,
    IntroComponent,
    VerdentumPrivacyMobileComponent,
    VerdentumPrivacyComponent,
    OrgPrivacyComponent,
    VideoComponent,
    InviteComponent,
    InviteGeneralComponent,
    InvitePartnerComponent,
    InviteSuccessComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PublicShareableDashboardComponent,
    SharePostComponent,
    // HighchartsChartComponent
    RfpFormComponent,
    ViewdataComponent,
    ViewSurveyComponent,
    PipComponent,
    RfqComponent,
  ],
  providers: [
    WithAuthGuard,
    WithoutAuthGuard,
    BsDatepickerConfig,
    CanCompDeactivateGuard,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    IconSetService,
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
