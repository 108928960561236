/**
    * @description      :
    * @author           : verdentum
    * @group            :
    * @created          : 10/08/2021 - 09:54:01
    *
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 10/08/2021
    * - Author          : verdentum
    * - Modification    :
**/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { apiUrl } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  response:any;
  apiLink = apiUrl+'/api/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  //===========get all currency=================
  async getAllCurrency() {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/get_all_currency', {}, {headers: headers}).toPromise().then(response => {

      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.currencies;
    });
  }

  //===========get all county=================
  async getAllCounties() {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/get_all_county', {}, {headers: headers}).toPromise().then(response => {

      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.counties;
    });
  }


  //===============add basic Info ===================//
  async addBasicInfo(basicDetails:any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    console.log(basicDetails);

    return this.http.post(this.apiLink+'project/add', basicDetails, {headers: headers}).toPromise().then(response => {
     // console.log(response)
      this.response = response;
      if(this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }


  //===========get all projects=================
  async getAllProjects(org_code = null, filter = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/get_all', {org_code: org_code, filter: filter}, {headers: headers}).toPromise().then(response => {

      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.projects;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  //===========get all projects for Task Mgt Dashboard / analysis=================
  async getAllProjectsTaskDash(org_code = null, filter = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/get_all_proj_dash', {org_code: org_code, filter: filter}, {headers: headers}).toPromise().then(response => {

      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.projects;
    });
  }

  //===========get all projects for public sharable dashboard=================
  async getAllProjectsShareDash(org_code = null, filter = {}) {
      let userDetails = await this.authService.getUserDetails(),
      token = userDetails.token ? userDetails.token : '';
  
      // Create header and append JWT token
      let headers = new HttpHeaders({
        'Authorization': `Bearer: ${token}`
      });
      headers.append('Authorization', token);
  
      return this.http.post(this.apiLink+'lookup/get_all_sharedashboard', {org_code: org_code, filter: filter}, {headers: headers}).toPromise().then(response => {
  
        this.response = response;
        if(this.response.status == 0) {
          this.toastr.error(this.response.msg, 'Error!', {
            timeOut: 5000,
          });
          return false;
        }
        return this.response.projects;
      });
  }

  //===============map project===================//
  async mapProject(mapDetails:any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/map', mapDetails, {headers: headers}).toPromise().then(response => {
     // console.log(response)
      this.response = response;
      if(this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  //===========get project and template details=================
  async getProjectAndTemplateDetails(proj_id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/get_details', {id: proj_id}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return {
        project: this.response.project,
        template: this.response.template
      };
    });
  }
  //===============get project details===================//
  async getProjectDetails(proj_id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/get_project_dash', {id: proj_id}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return {
        project: this.response.project,
        sdg: this.response.sdg
      };
    });
  }

  //===============get project details for public shareable dashboard===================//
  async getProjectDetailsShareDash(proj_id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'lookup/get_project_dash_share_dash', {id: proj_id}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return {
        project: this.response.project,
        sdg: this.response.sdg
      };
    });
  }


  //===============add project details===================//
  async addProject(projData, projDetails) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let data = {
      form_id: projDetails.id,
      user_id: userDetails.user.id,
      org_code: projDetails.org_code,
      form_data: JSON.stringify(projData)
    }
    return this.http.post(this.apiLink+'project/add', data, {headers: headers}).toPromise().then(response => {
     // console.log(response)
      this.response = response;
      if(this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return this.response.project_id;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  async updateProject(projData, projDetails, proj_id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let data = {
      proj_id: proj_id,
      form_data: JSON.stringify(projData)
    }
    return this.http.post(this.apiLink+'project/update', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async getAllMappingData(proj_id = null) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/get_all_mapping', {project: proj_id}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.mapping;
    });
  }

  async deleteMapping(mappedData:any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/delete_mapping', { proj_id: mappedData.proj_id, part_id: mappedData.part_id }, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  //===========get measurement modules=================
  async getGlobalMeasurementModules() {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/get_global_measurement', {}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.response.modules;
    });
  }

  async addMeasurementModule(measurementData) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);
    
    return this.http.post(this.apiLink+'project/update_measurement', measurementData, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      
      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  //===========get logframe=================
  async getLogframe(request) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'logframe/get_all', request, {headers: headers}).toPromise().then(response => {

      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.impacts;
    });
  }

  //===========get logframe for public sharebale dashboard=================
  async getLogframeShareDash(request) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'lookup/get_all_logframe_share_dash', request, {headers: headers}).toPromise().then(response => {

      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.impacts;
    });
  }

  //===============get activity metrics details===================//
  async getProjectActivityMetrics(proj_id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/get_project_activity_metrics', {id: proj_id}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.response.project;
     
    });
  }

  //===============get activity metrics details for public sharebale dashboard===================//
  async getProjectActivityMetricsShareDash(proj_id) {
      let userDetails = await this.authService.getUserDetails(),
      token = userDetails.token ? userDetails.token : '';
  
      // Create header and append JWT token
      let headers = new HttpHeaders({
        'Authorization': `Bearer: ${token}`
      });
      headers.append('Authorization', token);
  
      return this.http.post(this.apiLink+'lookup/get_project_activity_metrics_share_dash', {id: proj_id}, {headers: headers}).toPromise().then(response => {
        this.response = response;
        if(this.response.status == 0) {
          this.toastr.error(this.response.msg, 'Error!', {
            timeOut: 5000,
          });
          return false;
        }
  
        return this.response.project;
       
      });
  }

  //===============get activity map details===================//
  async getProjectActivityMap(proj_id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'project/get_project_activity_map', {id: proj_id}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.response.project;
     
    });
  }

  //===============get activity map details for public sharebale dashboard===================//
  async getProjectActivityMapShareDash(proj_id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'lookup/get_project_activity_map_share_dash', {id: proj_id}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.response.project;
     
    });
  }

  //===============get activity map details for public sharebale dashboard===================//
  async getShareDashUrl(org_code, proj) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'publicsharedash/public_link', {org_code: org_code, proj: proj}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      return this.response.link;
     
    });
  }

  //enable log-frmae in project
  async enableLogFrameProject(proj_id, measurement_id, org_code) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let request = {
      proj_id: proj_id,
      measurement_id: measurement_id,
      org_code: org_code
    };

    return this.http.post(this.apiLink+'project/update_project_logfrmae', request, {headers: headers}).toPromise().then(response => {
     // console.log(response)
      this.response = response;
      if(this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

}
